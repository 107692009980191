/* ---Roadhouse--- */
/* Splashpage */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.roadhouse * {
  @apply font-roadhouse;
}

.roadhouse .splash {
  font-weight: 700;
}

.roadhouse .splash button {
  color: white;
  font-weight: 700;
  background: #d5076e;
  border-radius: 30px;
  padding: 1rem;
}

/* .roadhouse .roadhouse_course_image {
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 80%,
    transparent 100%
  );
} */

.roadhouse .cta-item > div,
.roadhouse .modal-wrapper.ion-overlay-wrapper {
  background: #862328;
  color: white;
}

.roadhouse .cta-item .dish-variations > div div,
.roadhouse .cta-item .dish-additions > div div div {
  background: #251318;
  border-radius: 8px;
  color: white;
  border-left-width: 0;
}

.roadhouse .arrow-trigger {
  background: none;
  box-shadow: none;
  color: white;
}

.roadhouse ion-icon {
  color: white;
}

/* custom collapsibles */
.roadhouse .menu {
  padding: 0;
  background: #862328;
  border-radius: 0;
}

.roadhouse .collapsible-trigger.trigger {
  color: white;
}

.roadhouse .dishes {
  background-color: rgba(0, 0, 0, 0);
}
/* .calavera div.menu div:nth-child(1) span div {
  background: #f4d936;
  color: black;
}

.calavera div.menu div:nth-child(2) span div {
  background: #f09b32;
  color: black;
}

.calavera div.menu div:nth-child(3) span div {
  background: #e76149;
  color: black;
}

.calavera div.menu div:nth-child(4) span div {
  background: #dd265f;
  color: black;
}

.calavera div.menu div:nth-child(5) span div {
  background: #d91f7e;
  color: black;
}

.calavera div.menu div:nth-child(6) span div {
  background: #d92d85;
  color: black;
}

.calavera div.menu div:nth-child(7) span div {
  background: #de91b8;
  color: black;
} */

/* calavera collapsibles content */
/* .calavera div.menu div:nth-child(1) div .nested {
  background: #f9e540 !important;
  color: black;
} */

/* .calavera div.menu div:nth-child(2) div .nested {
  background: #f09b32;
  color: black;
} */

/* .calavera div.menu div:nth-child(3) div .nested {
  background: #e76149;
  color: black;
}
.calavera div.menu div:nth-child(4) div .nested {
  background: #dd265f;
  color: black;
} */
/* .calavera div.menu div:nth-child(5) div .nested {
  background: #d91f7e;
  color: black;
}
.calavera div.menu div:nth-child(6) div .nested {
  background: #d92d85;
  color: black;
}
.calavera div.menu div:nth-child(7) div .nested {
  background: #de91b8;
  color: black;
} */
/* --------- */
/* .calavera .nested .Collapsible .even {
  background: #f9e540;
  color: black;
}

.calavera .nested .Collapsible .odd {
  background: #f9e540;
  color: black;
} */

/* ------------ */
/* .calavera .nested div span div {
  border-radius: 10px;
  border: solid white 2px;
  margin: 1rem;
}

.calavera .nested > div > div {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: -1rem;
  border-radius: 10px;
  border-left: solid white 2px;
  border-right: solid white 2px;
}

.calavera .is-open {
  border-bottom: 0;
} */

.calavera .calavera-trigger-opened {
  border-bottom: 0;
}

.calavera .calavera-trigger-closed {
  border-bottom: solid white 2px;
}

.calavera .dish div div {
  border-radius: 20px;
  background: #faf2d5;
}

.roadhouse .cta-no-orders,
.roadhouse .cta-no-orders button {
  background: #ba0b31;
  border: #ba0b31;
  margin-top: 5px;
  border-radius: 12px;
  color: white;
}

.roadhouse .filter-modal-trigger {
  padding: 1rem;
  background: #ba0b31;
  border-color: #ba0b31;
  color: white;
  border-width: 2px;
  font-weight: 700;
}

.roadhouse .dish-modal-trigger {
  color: #b7172e;
}

.roadhouse .dish-modal-content,
.roadhouse .dish-modal-content p,
.roadhouse .dish-modal-content li,
.roadhouse .allergens-modal-content,
.roadhouse .allergens-modal-content h1 {
  color: white;
  border-color: white;
}

.roadhouse .allergens-modal-content li {
  background-color: #862328;
}

.roadhouse .dish-modal-content button ion-icon,
.roadhouse .allergens-modal-content button ion-icon {
  color: black;
}

.roadhouse .dish-modal-content .allergen-icons img {
  color: white;
  background: white;
  border-radius: 20px;
  width: 1.8rem;
  padding: 3px;
}

.roadhouse .allergens-modal-trigger span,
.roadhouse .allergens-modal-trigger a {
  border-color: #ba0b31;
  padding: 0.7rem;
  color: white;
  background: #ba0b31;
  border-width: 2px;
  font-weight: 700;
}

.roadhouse .powered-by {
  color: white;
  font-weight: bold;
  /* background-color: black; */
}

.calavera .powered-by a {
  color: black;
  font-weight: 800;
}
